import axios from "axios";
import authHeader from "./auth.header";
import { addTimezoneInformation } from "../utils/utils";

class ServantsService {

  searchQuery(query, startIndex, pageSize, sortOrder) {
    return axios({
      method: 'post',
      url: '/api/servants/search_with_query',
      headers: authHeader(),
      data: {
        svmids: query.svmid,
        vmids: query.vmid,
        hardware_type_id: query.hardwareType,
        product_id: query.product,
        region_id: query.region,
        store_number: query.storeNumber,
        installed: query.installed,
        enabled: query.enabled,
        bay_count: query.bayCount,
        paired: query.paired,
        start_index: startIndex,
        page_size: pageSize,
        sort_order: sortOrder
      }
    })
  }

  getServants(kioskId, sortOrder = -1) {
    return axios({
      method: 'get',
      url: '/api/servants/get_servants',
      headers: authHeader(),
      params: {
        kioskId,
        sortOrder
      }
    })
  }

  getById(servantId) {
    return axios({
      method: 'get',
      url: '/api/servants/get_by_id',
      headers: authHeader(),
      params: {
        servantId
      }
    })
  }

  update(servant) {
    return axios({
      method: 'post',
      url: '/api/servants/update',
      headers: authHeader(),
      data: {
        servant_id: servant.id,
        svmid: servant.svmid,
        hardware_type_id: servant.hardware_type_id,
        bay_count: servant.bay_count
      }
    })
  }

  pair(servantId, kioskId) {
    return axios({
      method: 'post',
      url: '/api/servants/pair',
      headers: authHeader(),
      params: {
        servantId,
        kioskId
      }
    })
  }

  unpair(servantId) {
    return axios({
      method: 'post',
      url: '/api/servants/unpair',
      headers: authHeader(),
      params: {
        servantId
      }
    })
  }

  delete(servantId) {
    return axios({
      method: 'post',
      url: '/api/servants/delete',
      headers: authHeader(),
      params: {
        servantId
      }
    })
  }

  importCSV(form_data) {
    var headers = {}

    Object.assign(headers, authHeader())
    Object.assign(headers, { "content-type": undefined })

    return axios({
      method: 'post',
      url: '/api/servants/bulk_new',
      headers: headers,
      data: form_data
    })
  }

  issues(servantId) {
    return axios({
        method: 'get',
        url: '/api/servants/issues',
        headers: authHeader(),
        params: {
          servantId
        }
    })
  }

  issuesHistory(servantId, query, startIndex, pageSize, sortOrder) {     
    return axios({
        method: 'post',
        url: '/api/servants/issues_history',
        headers: authHeader(),
        data: {
            servant_id: servantId,
            issue_type_id: query.issueTypeId,
            start_date: addTimezoneInformation(query.dateStart),
            end_date: addTimezoneInformation(query.dateEnd),
            start_index: startIndex,
            page_size: pageSize,
            sort_order: sortOrder
        }
    })
}

  currentLevels(servantId) {
    return axios({
        method: 'get',
        url: '/api/servants/current_levels',
        headers: authHeader(),
        params: {
          servantId
        }
    })
}

  carousel(servantId) {
    return axios({
        method: 'get',
        url: '/api/servants/carousel',
        headers: authHeader(),
        params: {
          servantId
        }
    })
  }

  firmware(servantId, kioskId) {
    return axios({
      method: 'get',
      url: '/api/servants/firmware',
      headers: authHeader(),
      params: {
        servantId,
        kioskId
      }
    })
  }

  downloadServantsCsv(query) {
    return axios({
      method: 'post',
      responseType: 'blob',
      url: '/api/servants/download_csv',
      headers: authHeader(),
      data: {
        svmids: query.svmid,
        vmids: query.vmid,
        hardware_type_id: query.hardwareType,
        product_id: query.product,
        region_id: query.region,
        store_number: query.storeNumber,
        installed: query.installed,
        enabled: query.enabled,
        bay_count: query.bayCount,
        paired: query.paired,
      }
    })
  }

  downloadServantsXlsx(query) {
    return axios({
      method: 'post',
      responseType: 'blob',
      url: '/api/servants/download_xlsx',
      headers: authHeader(),
      data: {
        svmids: query.svmid,
        vmids: query.vmid,
        hardware_type_id: query.hardwareType,
        product_id: query.product,
        region_id: query.region,
        store_number: query.storeNumber,
        installed: query.installed,
        enabled: query.enabled,
        bay_count: query.bayCount,
        paired: query.paired,
      }
    })
  }
}

export default new ServantsService();