<template>
    <div>
        <h3>
            <font-awesome-icon icon="money-bills" class="nav-icon fa" />
            Transactions
        </h3>

        <DynamicSearchComponent
            ref="transactionSearchComponent"
            preferencesName="transaction_search"
            :allCriteria="searchCriteria"
            :disableCurrentAndGreaterDates="true"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadTransactions"
        />
        <Table
            id="transactionTable"
            class="scrollable-table"
            :fields="dynamicFields"
            :items="transactions"
            sortBy=""
            :sortDesc="false"
            :searchPagination="searchPagination"
            :horizontalScroll="true"
            @getItems="getTransactions"
            @sortOrderChanged="sortOrderChanged"
        >
            <template #cell(actions)="row">
                <!-- {{row}} -->
                <div v-if="row.item.type != 'Open Bay'" class="icons">
                    <font-awesome-icon
                        class="nav-icon pointer mx-2"
                        icon="receipt"
                        @click="openReceipt(row.item)"
                        title="Receipt"
                    />
                    <font-awesome-icon
                        class="nav-icon mx-2"
                        icon="cut"
                        v-if="row.item.coupon_used"
                        title="Coupon Used"
                    />
                    <font-awesome-icon
                        class="nav-icon pointer mx-2"
                        icon="at"
                        @click="openEmailModal(row.item)"
                        title="Email Receipt"
                    />
                    <font-awesome-icon
                        class="nav-icon pointer mx-2"
                        icon="scroll"
                        v-if="
                            security.transactionLogsRead &&
                            row.item.has_logs
                        "
                        @click="setLogs(row.item)"
                        title="Transaction Logs"
                    />
                    <font-awesome-icon
                        class="nav-icon pointer mx-2"
                        icon="money-bill-transfer"
                        v-if="security.refundCreate"
                        @click="
                            row.item.can_be_refunded
                                ? openRefundMenu(row.item)
                                : null
                        "
                        :title="
                            row.item.can_be_refunded
                                ? 'Refund Transaction'
                                : 'Transaction is non-refundable'
                        "
                        :class="{ 'non-refundable': !row.item.can_be_refunded }"
                    />
                </div>
                <div v-else class="icons">
                    <font-awesome-icon class="nav-icon" icon="door-open" />
                    <font-awesome-icon
                        class="nav-icon pointer mx-2"
                        icon="scroll"
                        v-if="row.item.has_logs"
                        @click="setLogs(row.item)"
                        title="Transaction Logs"
                    />
                </div>
            </template>
            <template #cell(vmid)="data">
                <a
                    href="#"
                    @click="kioskLink(data.item.kiosk_id)"
                    class="underline"
                    >{{ data.item.vmid }}</a
                >
            </template>
            <template #cell(timestamp)="data">
                {{ formatDate(convertUTCtoLocalDate(data.item.timestamp)) }}
            </template>
            <template #cell(old_in)="data">
                {{
                    determineCarouselDisplay(
                        data.item.last_exchanged_carousel,
                        data.item.last_exchanged_locker
                    )
                }}
            </template>
            <template #cell(new_out)="data">
                {{
                    determineCarouselDisplay(
                        data.item.removed_from_locker_carousel,
                        data.item.removed_from_locker
                    )
                }}
            </template>
            <template #cell(price)="data">
                {{ formatPrice(data.item.price) }}
            </template>
            <template #cell(total)="data">
                {{ formatPrice(data.item.total) }}
            </template>
            <template #cell(refunded)="data">
                {{ formatPrice(data.item.refunded) }}
            </template>
            <template #cell(issues)="data">
                <template v-if="data.item.issues">
                    <div v-for="(issue, index) in data.item.issues.split(',')" :key="index">
                        •{{issue}}
                    </div>
                </template>
            </template>
        </Table>

        <ExportFileComponent
            :arguments="currentQuery"
            :disabled="false"
            page="transactions"
            class="export"
        />

        <TransactionLogModal :logs="logs"></TransactionLogModal>
        <TransactionRefundModal
            @refundComplete="refundComplete"
            :RefundInfo="refundInfo"
        ></TransactionRefundModal>
        <TransactionRefundResultModal
            :RefundResponse="refundResponse"
        ></TransactionRefundResultModal>
        <TransactionEmailReceiptModal
            @emailComplete="emailComplete"
            :transactionId="emailTransactionId"
        />
    </div>
</template>

<script>
import SearchPagination from '../../models/search.pagination';
import DynamicSearchComponent from '../../components/DynamicSearchComponent.vue';
import TransactionsService from '../../services/transactions.service';
import RolesService from '../../services/roles.service';
import EXCLUDED_COLUMN_TYPE_ENUM from '../../models/ExcludedColumnsTypes';
import ExportFileComponent from '../../components/ExportFileComponent.vue';
import { Table } from '../../components';
import {
    TransactionLogModal,
    TransactionRefundModal,
    TransactionRefundResultModal,
    TransactionEmailReceiptModal,
} from '../../components/transactions';
import {
    formatDate,
    formatPrice,
    generateFieldsByRole,
    kioskLink,
    convertUTCtoLocalDate,
    getDateOnly,
    isToday,
} from '../../utils/utils';

export default {
    name: 'Transactions',
    components: {
        Table,
        TransactionLogModal,
        TransactionRefundModal,
        TransactionRefundResultModal,
        TransactionEmailReceiptModal,
        DynamicSearchComponent,
        ExportFileComponent,
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
        roleId() {
            return this.$store.state.auth.user.role_id;
        },
        dateStart() {
            return this.$route.query.dateStart
                ? this.$route.query.dateStart
                : this.getDaysBack;
        },
        dateEnd() {
            return this.getYesterdaysDate;
        },
        vmid() {
            return this.$route.query.vmid;
        },
        transactionId() {
            return this.$route.query.transactionId;
        },
        dynamicFields() {
            return generateFieldsByRole(this.fields, this.excludedColumns);
        },
        searchCriteria() {
            let criteria = [
                {
                    key: 'date_start',
                    name: 'Start Date',
                    type: 'Date',
                        subtype: 'start-date',
                    searchQueryName: 'dateStart',
                    defaultValue: getDateOnly(
                        convertUTCtoLocalDate(this.dateStart)
                    ),
                    required: true,
                },
                {
                    key: 'date_end',
                    name: 'End Date',
                    type: 'Date',
                        subtype: 'end-date',
                    searchQueryName: 'dateEnd',
                    defaultValue: getDateOnly(
                        convertUTCtoLocalDate(this.dateEnd)
                    ),
                    required: true,
                },
                {
                    key: 'transaction_id',
                    name: 'Transaction ID',
                    type: 'Text',
                    searchQueryName: 'transactionId',
                    initial: this.transactionId ? true : false,
                    defaultValue: this.transactionId,
                },
                {
                    key: 'vmid',
                    name: 'VMID',
                    type: 'Text',
                    searchQueryName: 'vmid',
                    initial: this.vmid ? true : false,
                    defaultValue: this.vmid,
                },
                {
                    key: 'terminal_id',
                    name: 'Terminal ID',
                    type: 'Text',
                    searchQueryName: 'terminalId',
                },
                {
                    key: 'merchant_id',
                    name: 'Merchant ID',
                    type: 'Text',
                    searchQueryName: 'merchantId',
                },
                {
                    key: 'transaction_status',
                    name: 'Transaction Status',
                    type: 'TransactionStatus',
                    searchQueryName: 'transactionStatusId',
                },
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'product',
                    name: 'Product',
                    type: 'Product',
                    searchQueryName: 'productId',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'hardware_type',
                    name: 'Hardware Type',
                    type: 'HardwareType',
                    searchQueryName: 'hardwareTypeId',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'issue_type',
                    name: 'Issue Type',
                    type: 'IssueType',
                    searchQueryName: 'issueTypeId',
                },
                {
                    key: 'card_number',
                    name: 'Card Number',
                    type: 'CardNumber',
                    searchQueryName: 'card',
                    frontDescriptor: 'Start',
                    backDescriptor: 'End',
                },
                {
                    key: 'exclude_products',
                    name: "Don't Include Products Marked Excluded",
                    type: 'Boolean',
                    searchQueryName: 'excludeNotInOutput',
                    defaultValue: true,
                    required: true,
                },
                {
                    key: 'refundable',
                    name: 'Refundable',
                    type: 'Boolean',
                    searchQueryName: 'refundable',
                    defaultValue: true,
                },
            ];

            return criteria;
        },
        getDaysBack() {
            var daysBack = parseInt(
                this.$store.state.auth.portal_configuration.transdaysback,
                10
            );
            if (isNaN(daysBack)) {
                daysBack = 0;
            }

            const currentDate = new Date();
            // Start at yesterday's date. 
            currentDate.setDate((currentDate.getDate()-1) - daysBack);
            return currentDate.toISOString();
        },
        getYesterdaysDate() {
            const today = new Date(); 
            const yesterday = new Date(today); 
            yesterday.setDate(today.getDate() - 1);
            return yesterday.toISOString(); 
        }
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                    tdClass: 'action-column',
                },
                {
                    key: 'id',
                    label: 'Trans ID',
                    tdClass: 'column',
                    sortable: true,
                    columnId: 12,
                },
                {
                    key: 'timestamp',
                    label: 'Timestamp',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 13,
                },
                {
                    key: 'vmid',
                    label: 'VMID',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 14,
                },
                {
                    key: 'product_desc',
                    label: 'Product',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 15,
                },
                {
                    key: 'store_no',
                    label: 'Store #',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 16,
                },
                {
                    key: 'store_desc',
                    label: 'Store Description',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 17,
                },
                {
                    key: 'card_number',
                    label: 'Card Number',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 18,
                },
                {
                    key: 'card_type',
                    label: 'Card Type',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 19,
                },
                {
                    key: 'type',
                    label: 'Type',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 20,
                },
                {
                    key: 'price',
                    label: 'Price',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 21,
                },
                {
                    key: 'total',
                    label: 'Total',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 22,
                },
                {
                    key: 'charged',
                    label: 'Charged',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 23,
                },
                {
                    key: 'exchange_tank_returned',
                    label: 'Exchange Returned',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 24,
                },
                {
                    key: 'refunded',
                    label: 'Refunded',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 25,
                },
                {
                    key: 'auth_type',
                    label: 'Status',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 26,
                },
                {
                    key: 'issues',
                    label: 'Issues',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 27,
                },
                {
                    key: 'trans_msg',
                    label: 'Transaction Message',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 28,
                },
                {
                    key: 'old_in',
                    label: 'Old In',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 29,
                },
                {
                    key: 'new_out',
                    label: 'New Out',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 30,
                },
                {
                    key: 'mid',
                    label: 'MID',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 31,
                },
                {
                    key: 'tid',
                    label: 'TID',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 32,
                },
            ],
            transactions: [],
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -1,
            logs: [],
            title: '',
            refundInfo: {
                total: Number,
                exchangeTotal: Number,
                transactionType: Number,
                refundedAmount: Number,
                transactionID: Number,
            },
            refundResponse: {
                error: Boolean,
                success: Boolean,
                message: String,
                data: String,
            },
            emailTransactionId: NaN,
            excludedColumns: [],
            currentQuery: {},
            isHistory: false,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.getTransactions();
            this.getExcludedColumnsByRoleAndType();
        },
        getTransactions() {
            this.$refs.transactionSearchComponent.reloadDynamicSearch();
        },
        reloadTransactions(args) {
            this.isHistory = !isToday(args.query.dateStart);
            this.currentQuery = args.query;
            this.isLoading = true;
            if (this.isHistory) {
                TransactionsService.searchHistoryQuery(
                    args.query,
                    this.searchPagination.startIndex,
                    this.searchPagination.pageSize,
                    this.sortOrder
                ).then((response) => {
                    if (response) {
                        this.transactions = response.data.items;
                        this.searchPagination.totalRows =
                            this.transactions.length > 0
                                ? response.data.total_rows
                                : 0;
                        this.isLoading = false;
                        args.done();
                    }
                });
            } else {
                if (args.query.dateStart && args.query.dateEnd) {
                    args.query.dateEnd = args.query.dateStart;
                }

                TransactionsService.searchQuery(
                    args.query,
                    this.searchPagination.startIndex,
                    this.searchPagination.pageSize,
                    this.sortOrder
                ).then((response) => {
                    if (response) {
                        this.transactions = response.data.items;
                        this.searchPagination.totalRows =
                            this.transactions.length > 0
                                ? response.data.total_rows
                                : 0;
                        this.isLoading = false;
                        args.done();
                    }
                });
            }
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder;
            this.refresh();
        },
        setLogs(item) {
            TransactionsService.getTransactionLogs(item.transaction_guid).then((response) => {
                if (response) {
                    this.logs = response.data.logs;
                    this.$bvModal.show('transaction-log-modal');
                }
            });
        },
        openReceipt(item) {
            TransactionsService.getReceipt(item.id, this.isHistory).then((response) => {
                if (response) {
                    var contentType = response.headers['content-type'];
                    const downloadUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: contentType })
                    );

                    window.open(downloadUrl, '_blank');
                }
            });
        },
        openRefundMenu(item) {
            this.refundInfo.total = item.total;
            this.refundInfo.exchangeTotal = item.exchange_total;
            this.refundInfo.transactionType = item.type;
            this.refundInfo.refundedAmount = item.refunded_amount;
            this.refundInfo.transactionID = item.id;
            this.$bvModal.show('transaction-refund-modal');
        },
        refundComplete(response) {
            this.refundResponse.error = response.error;
            this.refundResponse.success = response.success;
            this.refundResponse.message = response.message;
            this.refundResponse.data = response.data;

            this.$bvModal.hide('transaction-refund-modal');
            this.$bvModal.show('transaction-refund-result-modal');
        },
        openEmailModal(item) {
            this.emailTransactionId = item.id;

            this.$bvModal.show('transaction-email-receipt-modal');
        },
        emailComplete() {
            this.$bvModal.hide('transaction-email-receipt-modal');
        },
        determineCarouselDisplay(carousel, locker) {
            if (carousel == -1) return '';
            if (locker == -1) return '';
            if (carousel == '') carousel = 1;
            locker = parseInt(locker);
            if (isNaN(locker)) return '';
            if (carousel == 1) return 'H1-' + locker;
            else return 'S' + (carousel - 1) + '-' + locker;
        },
        formatDate,
        convertUTCtoLocalDate,
        formatPrice,
        getExcludedColumnsByRoleAndType() {
            RolesService.getExcludedColumnsByRoleAndType(
                this.roleId,
                EXCLUDED_COLUMN_TYPE_ENUM.Transaction
            ).then((response) => {
                if (response) {
                    this.excludedColumns = response.data;
                }
            });
        },
        kioskLink,
        isToday,
    },
};
</script>

<style scoped>
.scrollable-table {
    width: auto;
    overflow-x: auto;
}

::v-deep .action-column {
    min-width: 160px;
    vertical-align: middle !important;
}

.underline {
    text-decoration: underline;
}

::v-deep .export > div {
    margin-top: -6rem !important;
}

.non-refundable {
    opacity: 0.5;
    cursor: not-allowed !important;
}

@media only screen and (max-width: 575.5px) {
    .icons {
        display: flex;
        justify-content: space-around;
    }

    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}
</style>