<template>
    <div>
        <h3><font-awesome-icon icon="hashtag" class="nav-icon fa" />  Transaction Codes</h3>
        <div class="button-group">
            <div class="button-col">
                <b-button @click="showAddTransactionCodeModal" variant="primary" class="button" v-if="security.promoCreate" >Add</b-button>
            </div>
        </div>
        <DynamicSearchComponent
            ref="transactionCodesSearchComponent"
            preferencesName="transaction_codes_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />
        <div class="pb-3">
            <Table
                id="transactionCodesTable"
                :fields="dynamicFields"
                :items="transactionCodes"
                sortBy="issued"
                :sortDesc="true"
                :searchPagination="searchPagination"
                @getItems="listTransactionCodes"
                @sortOrderChanged="sortOrderChanged"
            >
                <template #cell(actions)="data">
                    <div class="icons">
                        <font-awesome-icon icon="trash-can" class="nav-icon pointer mx-2" @click="showRemoveCodeModal(data.item)" v-if="security.promoDelete" title="Delete"/>
                    </div>
                </template>
                <template #cell(issued)="data">
                    {{ formatDate(convertUTCtoLocalDate(data.item.issued)) }}
                </template>
                <template #cell(endDate)="data">
                    {{ formatDate(convertUTCtoLocalDate(data.item.endDate)) }}
                </template>
                <template #cell(usedDate)="data">
                    {{
                        data.item.usedDate != '0001-01-01T00:00:00'
                            ? formatDate(convertUTCtoLocalDate(data.item.usedDate))
                            : 'N/A'
                    }}
                </template>
            </Table>
        </div>
        <b-modal
            id="add-transaction-code"
            ref="add-transaction-code"
            hide-footer
        >
            <template #modal-title> Transaction Code - New </template>
            <AddTransactionCode @hide="hideAddTransactionCodeModal" />
        </b-modal>
        <b-modal id="remove-code" ref="remove-code">
            <template #modal-title> Remove Transaction </template>
            <div class="d-block">
                <p>
                    You are about to remove item #{{
                        selectedTransactionCode.code
                    }}
                </p>
                <p>Do you want to proceed?</p>
            </div>
            <template #modal-footer>
                <button class="btn btn-danger" @click="removeCode(selectedTransactionCode)">
                    Remove
                </button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import SearchPagination from '../../models/search.pagination';
import { Table, DynamicSearchComponent } from '../../components/';
import AddTransactionCode from './AddTransactionCode.vue';
import PromosService from '../../services/promos.service';
import PROMO_TYPE_ENUM from '../../models/PromoTypeEnum';
import { generateDynamicFields, convertUTCtoLocalDate } from '../../utils/utils';

export default {
    name: 'Transaction',
    components: {
        DynamicSearchComponent,
        Table,
        AddTransactionCode,
    },
    data() {
        return {
            transactionCodes: [],
            selectedTransactionCode: {},
            fields: [
                {
                    key: 'actions',
                    tdClass: 'action-column',
                },
                {
                    key: 'code',
                    label: 'Code',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'type',
                    label: 'Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 4
                },
                {
                    key: 'location',
                    label: 'Location',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'issued',
                    label: 'Issued',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 13
                },
                {
                    key: 'endDate',
                    label: 'End Date',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 11
                },
                {
                    key: 'valid',
                    label: 'Valid',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'usedDate',
                    label: 'Used Date',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 14
                },
            ],
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -13,
            PROMO_TYPE_ENUM,
            searchCriteria: [
                {
                    key: 'code',
                    name: 'Code',
                    type: 'Text',
                    searchQueryName: 'code',
                },
            ],
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
        dynamicFields() {
            return generateDynamicFields(
                this.fields,
                'actions',
                !this.security.promoDelete
            );
        },
    },
    mounted() {
        this.listTransactionCodes();
    },
    methods: {
        convertUTCtoLocalDate,
        listTransactionCodes() {
            this.$refs.transactionCodesSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            PromosService.listCoupons(
                PROMO_TYPE_ENUM.Transaction,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder,
                args.query.code
            ).then((response) => {
                if (response) {
                    this.transactionCodes = response.data.items;
                    this.searchPagination.totalRows =
                        this.transactionCodes.length > 0
                            ? response.data.total_rows
                            : 0;
                    args.done();
                }
            });
        },
        showAddTransactionCodeModal() {
            this.$refs['add-transaction-code'].show();
        },
        hideAddTransactionCodeModal() {
            this.$refs['add-transaction-code'].hide();
            this.listTransactionCodes();
        },
        showRemoveCodeModal(transactionCode) {
            this.selectedTransactionCode = transactionCode;
            this.$refs['remove-code'].show();
        },
        removeCode() {
            PromosService.removeCode(this.selectedTransactionCode.id).then(
                (response) => {
                    if (response) {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true
                        })
                        this.hideRemoveCodeModal();
                        this.listTransactionCodes();
                    }
                }
            );
        },
        hideRemoveCodeModal() {
            this.$refs['remove-code'].hide();
            this.listTransactionCodes();
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.listTransactionCodes();
        },
        formatDate(date) {
            return new Date(Date.parse(date)).toLocaleString();
        },
    },
};
</script>
<style scoped>
::v-deep .action-column {
    min-width: 30px;
    vertical-align: middle !important;
}

.button-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
}

.button-col {
    display: flex;
    flex-direction: row;
}

.button {
    margin-right: 0.5rem;
}

@media only screen and (max-width: 575.5px) {
    .icons { 
        display: flex;
        justify-content: space-around;
    }

    .button-group {
        flex-direction: column;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 1rem;
    }
}
</style>