<template name="product-config-modal">
        <b-modal @submit.prevent="reloadRegisterEvent" @shown="get" id="product-config-modal">
            <template #modal-title>
                <h3>Product-Wide Configuration</h3>
            </template>
            <ProductCheckboxGroupComponent
                :allCheckboxes="config"
                :selectedCheckboxes="selected"
                @setSelectedCheckboxes="setSelectedProduct"
                name="product-checkbox-group"
                id="product-checkbox-group"
            />
            <template #modal-footer>
                <a id="products-modal-update-config" @click="updateConfig()" class="btn btn-primary">Update</a>
            </template>
        </b-modal>
</template>

<script>

import ProductsService from "../../../services/products.service"
import ProductCheckboxGroupComponent  from './ProductCheckboxGroupComponent.vue'
export default {
    name: "ProductConfigModal",
    components: {
        ProductCheckboxGroupComponent
    },
    data() {
        return {
            isLoading: false,
            message: "",
            config: [],
            selected: [],
            flagEffectiveDateRange: false
        }
    },
    props: {
        productDetails: {
            type: Object,
            required: true,
        }
    },
    emits: ['changeComplete'],
     methods: {
        reloadRegisterEvent(){
            this.$emit('reloadRegisterEvent');
        },
        setSelectedProduct(value) {
            this.config.forEach(element => {
                var found = false;
                value.forEach(details => {
                    if(details.name == element.name){
                        found = true;
                        details.is_overridden = true;
                    }
                });
                if(!found){
                    if(element.is_overridden){
                        element.is_deleted = true;
                    }
                    element.value = element.default_value
                }
                element.is_overridden = found;

            });
            this.selected = value;
        },
        get() {     
            this.config = [];
            this.selected = [];   
            ProductsService.getConfig(
                this.productDetails.id
            ).then((response) => {
                if (response) {
                    this.isLoading = false;
                    this.config = response.data.items;
                    this.config.forEach(element => {
                        if(element.is_overridden){
                            this.selected.push(element);
                        }
                    });
                }
            });
        },
        updateConfig() {
            this.selected.forEach(selections => {
                this.config.forEach(element => {
                    if(selections.name == element.name) {
                        element = selections;
                    }
                });
            });
            ProductsService.updateConfig(
                this.config,
                this.productDetails.id
            ).then((response) => {
                if (response) {
                    this.isLoading = false;
                    if(response.data.success){
                        this.$root.$bvToast.toast(response.data.message, {
                        title: 'Successful',
                        variant: 'success',
                        solid: true
                        })
                        this.$emit('changeComplete');
                    }
                    else {
                        this.$root.$bvToast.toast(response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                        })
                    }
                    this.$emit('changeComplete');
                }
            });
        }
    }
}
</script>

<style scoped>
.new-search {
    text-align: right;
    margin-right: 12px;
    position: relative;
    top: 38px;    
}
</style>