<template>
    <div>
        <h3>
            <font-awesome-icon icon="receipt" class="nav-icon fa" /> Sales
        </h3>

        <DynamicSearchComponent
            ref="salesSearchComponent"
            preferencesName="sales_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />

        <div class="pb-4">
            <Table
                id="salesTable"
                :fields="fields"
                :items="sales"
                :sortDesc="false"
                :searchPagination="searchPagination"
                :horizontalScroll="true"
                verticalScroll="500px"
                @getItems="getSales"
                @sortOrderChanged="sortOrderChanged"
                sortBy=""
            >
                <template #custom-foot>
                    <b-tr>
                        <b-th>Total</b-th>
                        <b-th colspan="5"></b-th>
                        <b-th>{{ totalExchangeQty }}</b-th>
                        <b-th>{{ totalExchangeSales }}</b-th>
                        <b-th>{{ totalPurchaseQty }}</b-th>
                        <b-th>{{ totalPurchaseSales }}</b-th>
                        <b-th>{{ totalSubTotal }}</b-th>
                        <b-th>{{ totalTaxA }}</b-th>
                        <b-th>{{ totalTax }}</b-th>
                        <b-th>{{ totalRefund }}</b-th>
                        <b-th>{{ totalGrandTotal }}</b-th>
                    </b-tr>
                </template>
            </Table>
            <ExportFileComponent
                :arguments="this.arguments.query"
                :searchPagination="this.searchPagination"
                :disabled="false"
                page="sales"
                class="export"
            />
        </div>
    </div>
</template>

<script>
import { Table } from '../../components';
import { DynamicSearchComponent, ExportFileComponent } from '../../components';
import SearchPagination from '../../models/search.pagination';
import { SalesService } from '../../services';
import { convertUTCtoLocalDate } from '../../utils/utils';


export default {
    name: 'SalesData',
    components: {
        Table,
        DynamicSearchComponent,
        ExportFileComponent,
    },
    data() {
        return {
            fields: [
                {
                    key: 'store',
                    label: 'Store',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'store_number',
                    label: 'Store Number',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'address',
                    label: 'Address',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'city',
                    label: 'City',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'province',
                    label: this.getProvinceString(),
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'region',
                    label: 'Region',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'exchange_qty',
                    label: 'Exchange Qty',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'exchange_sales',
                    label: 'Exchange Sales',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'purchase_qty',
                    label: 'Purchase Qty',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'purchase_sales',
                    label: 'Purchase Sales',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'sub_total',
                    label: 'SubTotal',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'tax_a',
                    label: 'Tax A',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'tax_b',
                    label: 'Tax',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'refund',
                    label: 'Refund',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'total',
                    label: 'Total',
                    tdClass: 'column',
                    sortable: false,
                },
            ],
            searchCriteria: [
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'include_incomm',
                    name: 'Incomm',
                    type: 'Boolean',
                    searchQueryName: 'includeIncomm',
                    defaultValue: false,
                },
                {
                    key: 'date_start',
                    name: 'Date Start',
                    type: 'Date',
                    searchQueryName: 'dateStart',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                    required: true,
                },
                {
                    key: 'date_end',
                    name: 'Date End',
                    type: 'Date',
                    searchQueryName: 'dateEnd',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                    required: true,
                },
            ],
            sales: [],
            arguments: [],
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -1,
            isLoading: false,
            totalExchangeQty: '',
            totalExchangeSales: '',
            totalPurchaseQty: '',
            totalPurchaseSales: '',
            totalSubTotal: '',
            totalTaxA: '',
            totalTax: '',
            totalRefund: '',
            totalGrandTotal: ''
        };
    },
    mounted() {
    },
    methods: {
        convertUTCtoLocalDate,
        getSales() {
            this.$refs.salesSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.arguments = args;
            this.isLoading = true;
            SalesService.sales(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then((response) => {
                this.sales = response.data.items;
                this.searchPagination.totalRows =
                    this.sales.length > 0 ? response.data.total_rows : 0;
                this.totalExchangeQty = response.data.total_sales.exchange_qty;
                this.totalExchangeSales = response.data.total_sales.exchange_sales;
                this.totalPurchaseQty = response.data.total_sales.purchase_qty;
                this.totalPurchaseSales = response.data.total_sales.purchase_sales;
                this.totalSubTotal = response.data.total_sales.sub_total;
                this.totalTaxA = response.data.total_sales.tax_a;
                this.totalTax = response.data.total_sales.tax_b;
                this.totalRefund = response.data.total_sales.refund;
                this.totalGrandTotal = response.data.total_sales.total;
                this.isLoading = false;
                args.done();
            });
        },
        sortOrderChanged() {},
        getProvinceString() {
            return this.$store.state.auth.portal_configuration?.province ?? "Province";
        },
        
    },
};
</script>

<style scoped>

::v-deep .export > div {
    margin-top: 0rem !important;
}

@media only screen and (max-width: 575.5px) {
    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}

</style>