import axios from "axios";
import authHeader from "./auth.header";
import { addTimezoneInformation } from "../utils/utils";

class TransactionsService {

    search(startIndex, pageSize, sortOrder) {
        return axios({
            method: 'get',
            url: '/api/transactions/search',
            headers: authHeader(),
            params: {
                startIndex,
                pageSize,
                sortOrder
            }
        })
    }

    searchQuery(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/transactions/search_with_query',
            headers: authHeader(),
            data: {
                transaction_id: query.transactionId == '' ? 0 : query.transactionId,
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                vmid: query.vmid,
                terminal_id: query.terminalId,
                merchant_id: query.merchantId,
                transaction_status_id: query.transactionStatusId,
                region_id: query.region,
                product_id: query.productId,
                hardware_type_id: query.hardwareTypeId,
                issue_type_id: query.issueTypeId,
                card_start: query.cardStart,
                card_end: query.cardEnd,
                exclude_not_in_output: query.excludeNotInOutput,
                refundable: query.refundable,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }

    searchHistoryQuery(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/transactions/search_history_with_query',
            headers: authHeader(),
            data: {
                transaction_id: query.transactionId == '' ? 0 : query.transactionId,
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                vmid: query.vmid,
                terminal_id: query.terminalId,
                merchant_id: query.merchantId,
                transaction_status_id: query.transactionStatusId,
                region_id: query.region,
                product_id: query.productId,
                hardware_type_id: query.hardwareTypeId,
                issue_type_id: query.issueTypeId,
                card_start: query.cardStart,
                card_end: query.cardEnd,
                exclude_not_in_output: query.excludeNotInOutput,
                refundable: query.refundable,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }

    getTransactionStatuses() {
        return axios({
            method: 'get',
            url: '/api/transactions/statuses',
            headers: authHeader()
        })
    }

    getTransactionLogs(guid) {
        return axios({
            method: 'get',
            url: '/api/transactions/get_logs',
            params: {
                id: guid
            },
            headers: authHeader()
        })
    }

    getReceipt(id, isHistory) {
        return axios({
            method: 'get',
            url: '/api/transactions/generate_receipt',
            params: {
                id: id,
                isHistory: isHistory
            },
            headers: authHeader(),
            responseType: 'blob'
        })
    }

    refundTransaction(id, amount, expMonth, expYear, type) {
        return axios({
            method: 'post',
            url: '/api/transactions/refund_transaction',
            params: {
                id,
                amount,
                exp_month: expMonth,
                exp_year: expYear,
                type
            },
            headers: authHeader()
        })
    }

    emailReceipt(id, email) {
        return axios({
            method: 'post',
            url: '/api/transactions/email_receipt',
            params: {
                id,
                email
            },
            headers: authHeader()
        })
    }

    searchRefunds(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/transactions/search_refunds',
            headers: authHeader(),
            data: {
                vmid: query.vmid,
                refund_id: query.refundId,
                transaction_id: query.transactionId,
                transaction_type: query.transactionType,
                refund_type: query.refundType,
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                min_amount: query.minAmount,
                max_amount: query.maxAmount,
                product_id: query.productId,
                region_id: query.region,
                hardware_type_id: query.hardware_type,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }

    getTransactionTypes() {
        return axios({
            method: 'get',
            url: '/api/transactions/get_transaction_types',
            headers: authHeader()
        })
    }

    getRefundTypes() {
        return axios({
            method: 'get',
            url: '/api/transactions/get_refund_types',
            headers: authHeader()
        })
    }

    downloadCsv(query) {
        return axios({
            method: 'post',
            responseType: "blob",
            url: '/api/transactions/download_csv',
            headers: authHeader(),
            data: {
                transaction_id: query.transactionId == '' ? 0 : query.transactionId,
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                vmid: query.vmid,
                terminal_id: query.terminalId,
                merchant_id: query.merchantId,
                transaction_status_id: query.transactionStatusId,
                region_id: query.region,
                product_id: query.productId,
                hardware_type_id: query.hardwareTypeId,
                issue_type_id: query.issueTypeId,
                card_start: query.cardStart,
                card_end: query.cardEnd,
                exclude_not_in_output: query.excludeNotInOutput,
                sort_order: query.sortOrder
            }
        })
    }

    downloadXlsx(query) {
        return axios({
            method: 'post',
            responseType: "blob",
            url: '/api/transactions/download_xlsx',
            headers: authHeader(),
            data: {
                transaction_id: query.transactionId == '' ? 0 : query.transactionId,
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                vmid: query.vmid,
                terminal_id: query.terminalId,
                merchant_id: query.merchantId,
                transaction_status_id: query.transactionStatusId,
                region_id: query.region,
                product_id: query.productId,
                hardware_type_id: query.hardwareTypeId,
                issue_type_id: query.issueTypeId,
                card_start: query.cardStart,
                card_end: query.cardEnd,
                exclude_not_in_output: query.excludeNotInOutput,
                sort_order: query.sortOrder
            }
        })
    }

    downloadRefundsCsv(query) {
        return axios({
            method: 'post',
            responseType: "blob",
            url: '/api/transactions/download_refunds_csv',
            headers: authHeader(),
            data: {
                vmid: query.vmid,
                refund_id: query.refundId,
                transaction_id: query.transactionId,
                transaction_type: query.transactionType,
                refund_type: query.refundType,
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                min_amount: query.minAmount,
                max_amount: query.maxAmount,
                product_id: query.productId,
                region_id: query.region,
                hardware_type_id: query.hardware_type
            }
        })
    }

    downloadRefundsXlsx(query) {
        return axios({
            method: 'post',
            responseType: "blob",
            url: '/api/transactions/download_refunds_xlsx',
            headers: authHeader(),
            data: {
                vmid: query.vmid,
                refund_id: query.refundId,
                transaction_id: query.transactionId,
                transaction_type: query.transactionType,
                refund_type: query.refundType,
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                min_amount: query.minAmount,
                max_amount: query.maxAmount,
                product_id: query.productId,
                region_id: query.region,
                hardware_type_id: query.hardware_type
            }
        })
    }
}

export default new TransactionsService();