<template>
    <div class="user-update-modal">
        <b-modal @submit.prevent="updateUser" id="users-update-modal" @show="refresh">
            <template #modal-title>
                <h3>Update User</h3>
            </template>
            <div class="row row-cols-1 row-cols-md-2 g-5">
                <div class="col">
                    <b-form-group
                        id="username-input-group"
                        label="Username"
                        label-for="username"
                    >
                        <b-form-input
                            id="username"
                            v-model="internalUser.username"
                            :disabled="true"
                            :state="usernameState"
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="usernameState == false" class="error">
                        Username is required
                    </div>
                </div>
                <div class="col">
                    <b-form-group
                        id="name-input-group"
                        label="Name"
                        label-for="name"
                    >
                        <b-form-input
                            id="name"
                            v-model="internalUser.name"
                            :state="nameState"
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="nameState == false" class="error">
                        Name is required
                    </div>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-1 g-5">
                <div class="col">
                    <b-form-group
                        id="email-input-group"
                        label="Email"
                        label-for="email"
                    >
                        <b-form-input
                            id="email"
                            v-model="internalUser.email"
                            :state="emailState"
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="emailState == false" class="error">
                        Email is an invalid format
                    </div>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-1 g-5 pl-3 pr-3">
                <ClaimRoleComponent 
                    v-model="internalUser.role"
                    :state="roleState"
                    :disabled="internalUser.role.is_technician"
                />
                <div v-if="roleState == false" class="error">
                    Role is required
                </div>
            </div>
                <b-form-group v-if="internalUser.role.is_technician" id="access-level-group" label="Access Level" label-for="access-level-input">
                        <b-form-input
                            id="access-level-input"
                            v-model="internalUser.access_level"
                            type="number"
                            :min="minAccessLevel"
                            :max="maxAccessLevel"
                            :state="levelState"
                        ></b-form-input>
                    </b-form-group>
                    <div class="alert alert-info">
                        {{ accessLevelHelpMessage }}
                    </div>
            
            <div class="form-group message pt-3">
                <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                </div>
            </div>
            <template #modal-footer>
                <a id="events-modal-register-event" @click="updateUser()" class="btn btn-primary">Update</a>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { UsersService } from '../../services';
import { ClaimRoleComponent } from '../../components';
import RolesService from '../../services/roles.service'

import USER_TYPE_ENUM from '../../models/UserTypeEnum'

export default {
    name: 'UpdateUserModal',
    components: { ClaimRoleComponent },
    data() {
        return {
            roles: [],
            message: '',
            internalUser: {
                role: {}
            },
            checkValid: false,
            USER_TYPE_ENUM,
            currentUserIsTechnician: false
        };
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    emits: ['changeComplete'],
    mounted() {
        this.getRoleIsTechnician();
    },
    computed: {
        security() {
            return this.$store.state.auth.security
        },
        currentUser() {
            return this.$store.state.auth.user;
        },
        usernameState() {
            if (!this.checkValid) return null;
            return this.internalUser.username ? true : false;
        },
        nameState() {
            if (!this.checkValid) return null;
            return this.internalUser.name ? true : false;
        },
        emailState() {
            if (!this.checkValid) return null;
            const regex = new RegExp('[a-z0-9]+@[a-z0-9]+\\.[a-z]{2,3}')
            return regex.test(this.internalUser.email)
        },
        roleState() {
            if (!this.checkValid) return null;
            return this.internalUser.role ? true : false;
        },
        isFormValid() {
            return this.usernameState && this.nameState && this.emailState && this.roleState
        },
        levelState() {
            if (!this.checkValid) return null
            return (this.internalUser.access_level >= this.minAccessLevel && this.internalUser.access_level <= this.maxAccessLevel)
        },
        minAccessLevel() {
            if (this.internalUser.user_type_id == USER_TYPE_ENUM.PortalUser)
                return 30
            return 1
        },
        maxAccessLevel() {
            if (this.security.technician) {
                return 99
            }
            return 89
        },
        accessLevelHelpMessage() {
            if (this.security.technician) {
                return "Access level 1-29 is kiosk restock and 30-99 is kiosk technician"
            } else {
                return "Access level 1-29 is kiosk restock and 30-89 is kiosk technician"
            }
        }
    },
    watch: {
        user() {
            this.internalUser = Object.assign({}, this.user)
        }
    },
    methods: {
        refresh() {
            this.internalUser = Object.assign({}, this.user)
            this.checkValid = false
        },
        updateUser() {
            this.checkValid = true

            if (this.internalUser.role.is_technician) {
                if (this.internalUser.access_level < this.minAccessLevel || this.internalUser.access_level > this.maxAccessLevel) {
                    this.message = `Please provide access level from ${this.minAccessLevel} to ${this.maxAccessLevel}`
                    return
                }
                else if (this.internalUser.access_level === undefined) {
                    this.message = "Please provide an access level"
                    return
                }
            } else {
                // Reset access level to null if role is not a technician admin
                this.internalUser.accessLevel = undefined
            }

            if (this.isFormValid) {
                this.message = '';
                UsersService.update(
                    this.internalUser.id,
                    this.internalUser.username,
                    this.internalUser.name,
                    this.internalUser.email,
                    this.internalUser.role.description,
                    this.internalUser.access_level
                ).then((response) => {
                    if (response.data.success) {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: 'Update User Details Successful',
                            variant: 'success',
                            solid: true,
                        });
                        this.message = '';
                        this.$emit('changeComplete');
                    } else {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: 'Update User Error',
                            variant: 'danger',
                            solid: true,
                        });
                    }
                });
            }
        },
        getRoleIsTechnician() {
            RolesService.isTechnician().then((response) => {
                if(response) {
                    this.currentUserIsTechnician = response.data
                }
            })
        }
    },
};
</script>
<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>