<template>
    <div class="table-container">
    <DynamicSearchComponent
        ref="issuesHistoryComponent"
        preferencesName="servant_issues_history"
        :allCriteria="searchCriteria"
        @resetPagination="
            () => {
                this.searchPagination.currentPage = 1
                this.searchPagination.startIndex = 1
            }
        "
        @reloadDynamicSearch="reloadDynamicSearch"
    />
    <span v-if="issues.length == 0">{{noResultsMessage}}</span>
    <Table
        id="issuesHistoryTable"
        :fields="fields"
        :items="issues"
        sortBy="end_date"
        :sortDesc="true"
        :searchPagination="searchPagination"
        @getItems="getIssuesHistory"
        @sortOrderChanged="sortOrderChanged"
        v-else
    >
        <template #cell(start_date)="data">
            {{ formatDate(convertUTCtoLocalDate(data.item.start_date)) }}
        </template>
        <template #cell(end_date)="data">
            {{ formatDate(convertUTCtoLocalDate(data.item.end_date)) }}
        </template>
    </Table>
    </div>
</template>
<script>
import { Table, DynamicSearchComponent } from "../../../components"
import SearchPagination from '../../../models/search.pagination';
import { formatDate, convertUTCtoLocalDate } from '../../../utils/utils'
import ServantsService from "../../../services/servants.service"
  
  export default {
      name: "ServantIssuesHistoryComponent",
      components: {
          Table,
          DynamicSearchComponent,
      },
      props: {
          servantId: {
              type: Number,
              require: true
          }
      },
      computed: {
          noResultsMessage() {
              return "No issue history found "
          }
      },
      data() {
          return {
              fields: [
                  {
                      key: 'issue',
                      label: 'Issue',
                      tdClass: 'column',
                      sortable: false,
                      sortOrder: 1
                  },
                  {
                      key: 'value',
                      label: 'Value',
                      tdClass: 'column',
                      sortable: false
                  },
                  {
                      key: 'servant_number',
                      label: 'Carousel',
                      tdClass: 'column',
                      sortable: false
                  },
                  {
                      key: 'start_date',
                      label: 'Start Date',
                      tdClass: 'column',
                      sortable: true,
                      sortOrder: -2
                  },
                  {
                    key: 'end_date',
                    label: 'End Date',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: -3
                  }
              ],
              searchCriteria: [
                {
                    key: 'issue_type',
                    name: 'Issue Type',
                    type: 'IssueType',
                    searchQueryName: 'issueTypeId',
                },
                {
                    key: 'date_start',
                    name: 'Date Start',
                    type: 'Date',
                    searchQueryName: 'dateStart',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                },
                {
                    key: 'date_end',
                    name: 'Date End',
                    type: 'Date',
                    searchQueryName: 'dateEnd',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                },
            ],
            issues: [],
            isLoading: false,
            arguments: {},
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -2
          };
      },
      mounted() {
        this.arguments = { servantId: this.servantId }
        this.getIssuesHistory()
      },
      methods: {
        formatDate,
        convertUTCtoLocalDate,
        getIssuesHistory() {
            this.$refs.issuesHistoryComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.arguments = args
            this.arguments.servantId = this.servantId
            this.isLoading = false
            ServantsService.issuesHistory(
                this.servantId,
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then((response) => {
                    if (response) {
                        this.issues = response.data.items
                        this.searchPagination.totalRows =
                            this.issues.length > 0
                                ? response.data.total_rows
                                : 0;
                    }
                    args.done();
                })
                .catch(() => {
                    args.done();
            });

        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.getIssuesHistory()
        },
      }
  }
  </script>
  <style scoped>
  
  </style>